import { Builder } from "builder-pattern";
import { Entity } from "./entity";
import { Identity } from "./vo/identity";
import { PrimitiveDataFactory } from "./factory/primitve-data-factory";

export const STANDARD_EVENT_CREATED = "EntityCreated";
export const STANDARD_EVENT_UPDATED = "EntityUpdated";
export const STANDARD_EVENT_DELETED = "EntityDeleted";

export interface EntityEvent<T> {
  sourceId: string;
  entityId: string;
  entityType: string;
  when: Date;
  eventType: string;
  data?: T;
}

export interface EntityEventSearch {
  entityId: string;
  entityType: string;
  eventType?: string;
  sort?: EntityEventSort;
}

export interface EntityEventSort {
  whenDecreasing?: boolean;
}

export interface EntityEventHandler<T> {
  handle(event: EntityEvent<T>, identity: Identity): Promise<void>;
}

export class EntityEventFactory {
  public static create<T>(params: {
    entityId: string;
    entityType: string;
    eventType: string;
    data?: T;
  }): EntityEvent<T> {
    let now = new Date();
    return Builder<EntityEvent<T>>()
      .sourceId(params.entityType + "@" + params.entityId)
      .entityId(params.entityId)
      .entityType(params.entityType)
      .eventType(params.eventType)
      .data(params.data)
      .when(now)
      .build();
  }
}
