import * as ShortId from "shortid";

export class PrimitiveDataFactory {
  public static string(prefix?: string, length?: number): string {
    let now = new Date();
    let p = "string";
    if (prefix) {
      p = prefix;
    }
    let str = p + now.getTime();

    if (length && length <= str.length) {
      return str.substr(0, length);
    }
    return str;
  }

  public static number(range?: {
    inclusiveFrom?: number;
    exclusiveTo?: number;
  }): number {
    let from = 0;
    let to = 100;
    if (range) {
      if (range.inclusiveFrom) {
        from = range.inclusiveFrom;
      }
      if (range.exclusiveTo) {
        to = range.exclusiveTo;
      }
    }
    if (to <= from) {
      throw new Error(`Invalid range ${JSON.stringify(range)}`);
    }

    return Math.floor(Math.random() * (to - from));
  }

  public static date(range?: {
    inclusiveFrom?: Date;
    exclusiveTo?: Date;
  }): Date {
    let from = new Date().getTime();
    let to = new Date().getTime();
    if (range) {
      if (range.inclusiveFrom) {
        from = range.inclusiveFrom.getTime();
      }
      if (range.exclusiveTo) {
        to = range.exclusiveTo.getTime();
      }
    }
    if (to <= from) {
      throw new Error(`Invalid range ${JSON.stringify(range)}`);
    }
    return new Date(Math.floor(Math.random() * (to - from)));
  }

  public static id(): string {
    return ShortId.generate();
  }
}
