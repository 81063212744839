import { Builder } from "builder-pattern";
import { Entity } from "./entity";

export interface EntityHistory<T> {
  entityId: string;
  entityType: string;
  entityVersion: number;
  from: Date;
  to: Date;
  data?: T;
}

export interface EntityHistorySearch {
  entityId: string;
  entityType: string;
  sort?: EntityHistorySort;
}

export interface EntityHistorySort {
  entityVersionDecreasing?: boolean;
}

export class EntityHistoryFactory {
  public static createEntityHistory<T>(entity: Entity<any>, data?: T): EntityHistory<T> {
    const now = new Date();
    return Builder<EntityHistory<T>>()
      .entityId(entity.id)
      .entityType(entity.type)
      .entityVersion(entity.version)
      .from(entity.creationDate)
      .to(now)
      .data(data)
      .build();
  }
}
