import { Builder } from "builder-pattern";
import { diff } from "deep-diff";
import { PrimitiveDataFactory } from "./factory/primitve-data-factory";
import { Identity, IdentityFactory } from "./vo/identity";
import { Tag } from "./vo/tag";
import shortid = require("shortid");
import deepEqual = require("deep-equal");

export interface Entity<T> {
  id: string;
  type: string;
  creationDate: Date;
  createdBy: Identity;
  lastUpdatedDate: Date;
  lastUpdatedBy: Identity;
  lastUpdateType: string;
  lastUpdateDiff: string;
  version: number;
  data: T;
  tags: Tag[];
  labels: string[];
  active: boolean;
  deleted: boolean;
}

export interface EntityRef {
  id: string;
  type: string;
}

export interface EntityVersionRef {
  id: string;
  type: string;
  version: number;
}

export class EntityFactory {
  public static updateEntityData<T>(params: {
    entity: Entity<T>;
    newData: T;
    by: Identity;
  }): Entity<T> {
    let now = new Date();
    if (deepEqual(params.entity.data, params.newData)) {
      console.log(`new data is same as old data. Ignoring`);
      return params.entity;
    }
    return Builder<Entity<T>>(params.entity)
      .data(params.newData)
      .lastUpdatedBy(params.by)
      .lastUpdatedDate(now)
      .lastUpdateType("UpdateData")
      .lastUpdateDiff(JSON.stringify(diff(params.entity.data, params.newData)))
      .version(params.entity.version + 1)
      .build();
  }

  public static updateLabels<T>(params: {
    entity: Entity<T>;
    labels: string[];
    by: Identity;
  }): Entity<T> {
    if (deepEqual(params.labels, params.entity.labels)) {
      return params.entity;
    }
    let now = new Date();
    let builder = Builder(params.entity);

    if (params.labels) {
      builder = builder
        .labels(params.labels)
        .lastUpdateType("UpdateLabels")
        .lastUpdatedBy(params.by)
        .lastUpdatedDate(now)
        .lastUpdateDiff(
          JSON.stringify(diff(params.entity.labels, params.labels))
        )
        .version(params.entity.version + 1);
    }
    return builder.build();
  }

  public static updateTags<T>(params: {
    entity: Entity<T>;
    tags: Tag[];
    by: Identity;
  }): Entity<T> {
    if (deepEqual(params.tags, params.entity.tags)) {
      return params.entity;
    }
    let now = new Date();
    let builder = Builder(params.entity);

    if (params.tags) {
      builder = builder
        .tags(params.tags)
        .lastUpdateType("UpdateTags")
        .lastUpdatedBy(params.by)
        .lastUpdatedDate(now)
        .lastUpdateDiff(JSON.stringify(diff(params.entity.tags, params.tags)))
        .version(params.entity.version + 1);
    }
    return builder.build();
  }

  public static updateEntityLabels<T>(params: {
    entity: Entity<T>;
    labels: string[];
    by: Identity;
  }): Entity<T> {
    let now = new Date();
    return Builder<Entity<T>>(params.entity)
      .lastUpdatedBy(params.by)
      .lastUpdatedDate(now)
      .lastUpdateType("Delete")
      .lastUpdateDiff(JSON.stringify(diff(params.entity.data, {})))
      .deleted(true)
      .version(params.entity.version + 1)
      .build();
  }

  public static deleteEntity<T>(params: {
    entity: Entity<T>;
    by: Identity;
  }): Entity<T> {
    let now = new Date();
    return Builder<Entity<T>>(params.entity)
      .lastUpdatedBy(params.by)
      .lastUpdatedDate(now)
      .lastUpdateType("Delete")
      .lastUpdateDiff(JSON.stringify(diff(params.entity.data, {})))
      .deleted(true)
      .version(params.entity.version + 1)
      .build();
  }

  public static toEntityVersionRef<T>(entity: Entity<T>): EntityVersionRef {
    return Builder<EntityVersionRef>()
      .id(entity.id)
      .type(entity.type)
      .version(entity.version)
      .build();
  }

  public static toEntityRef<T>(entity: Entity<T>): EntityRef {
    return Builder<EntityRef>().id(entity.id).type(entity.type).build();
  }

  public static deactivateEntity<T>(entity: Entity<T>): Entity<T> {
    return Builder<Entity<T>>(entity)
      .active(false)
      .lastUpdateType("Deactivate")
      .lastUpdateDiff("{}")
      .build();
  }

  public static activateEntity<T>(entity: Entity<T>): Entity<T> {
    return Builder<Entity<T>>(entity)
      .active(true)
      .lastUpdateType("Activate")
      .lastUpdateDiff("{}")
      .build();
  }

  public static createSampleEntity<T>(data: T): Entity<T> {
    let identity = IdentityFactory.identity();
    let type = PrimitiveDataFactory.string("Type");
    return EntityFactory.create({
      data: data,
      type: type,
      identity: identity,
    });
  }

  public static create<T>(params: {
    id?: string;
    data: T;
    type: string;
    labels?: string[];
    tags?: Tag[];
    identity: Identity;
  }): Entity<T> {
    if (!params.identity) {
      throw new Error("Required data is missing");
    }
    if (!params.data) {
      throw new Error("Required data is missing");
    }
    if (!params.type) {
      throw new Error("Required data is missing");
    }
    let now = new Date();
    let builder = Builder<Entity<T>>()
      .id(shortid.generate())
      .data(params.data)
      .type(params.type)
      .creationDate(now)
      .createdBy(params.identity)
      .lastUpdatedDate(now)
      .lastUpdatedBy(params.identity)
      .lastUpdateType("Create")
      .lastUpdateDiff(JSON.stringify(diff({}, params.data)))
      .version(0)
      .active(true)
      .deleted(false)
      .labels([])
      .tags([]);
    if (params.id) {
      builder = builder.id(params.id);
    }
    if (params.labels) {
      builder = builder.labels(params.labels);
    }
    if (params.tags) {
      builder = builder.tags(params.tags);
    }
    return builder.build();
  }
}
