import { PrimitiveDataFactory } from "../factory/primitve-data-factory";

export enum IdentityType {
  Person,
  System,
}

export interface Identity {
  type: IdentityType;
  id: string;
  groupIdentities?: GroupIdentity[];
}

export enum GroupIdentityType {
  LdapGroup,
  PosixGroup,
  Team,
}

export interface GroupIdentity {
  type: GroupIdentityType;
  id: string;
}

export class IdentityFactory {
  public static identity(type?: IdentityType, id?: string): Identity {
    let t = IdentityType.Person;
    let i = PrimitiveDataFactory.id();
    if (type) {
      t = type;
    }
    if (id) {
      i = id;
    }
    return {
      type: t,
      id: i,
    };
  }

  public static groupIdentity(
    type?: GroupIdentityType,
    id?: string
  ): GroupIdentity {
    let t = GroupIdentityType.Team;
    let i = PrimitiveDataFactory.id();
    if (type) {
      t = type;
    }
    if (id) {
      i = id;
    }
    return {
      type: t,
      id: i,
    };
  }
}
