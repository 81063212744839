import { Builder } from "builder-pattern";
import { EntityRef } from "./entity";
import { GroupIdentity, Identity } from "./vo/identity";

export interface EntityAuthorization {
  entityRef: EntityRef;
  entityRoleRef: EntityRef;
  groupIdentities?: GroupIdentity[];
  identities?: Identity[];
}

export class EntityAuthorizationFactory {
  public static createSample(
    entityRef: EntityRef,
    entityRoleRef: EntityRef
  ): EntityAuthorization {
    let now = new Date();
    return Builder<EntityAuthorization>()
      .entityRef(entityRef)
      .entityRoleRef(entityRoleRef)
      .build();
  }
}
