import { Builder } from "builder-pattern";
import { PrimitiveDataFactory } from "./factory/primitve-data-factory";

export interface EntityPermission {
  entityType: string;
  permissionName: string;
  description: string;
}

export class EntityPermissionFactory {
  public static createSample(): EntityPermission {
    return Builder<EntityPermission>()
      .entityType("SampleEntityType")
      .description("SampleDescription")
      .permissionName(PrimitiveDataFactory.string("SamplePermission"))
      .build();
  }
}
