import { Builder } from "builder-pattern";
import { PrimitiveDataFactory } from "./factory/primitve-data-factory";

export interface EntityRole {
  entityType: string;
  roleName: string;
  description: string;
  permissionNames: string[];
}

export class EntityRoleFactory {
  public static createSample(): EntityRole {
    return Builder<EntityRole>()
      .entityType(PrimitiveDataFactory.string("SampleEntityType"))
      .roleName(PrimitiveDataFactory.string("Role"))
      .description(PrimitiveDataFactory.string("Description"))
      .build();
  }
}
