import { Builder } from "builder-pattern";
import { Entity } from "./entity";
import { Identity } from "./vo/identity";

export interface EntityAudit {
  entityId: string;
  entityType: string;
  when: Date;
  updatedBy: Identity;
  updateType: string;
  updateSummary?: string;
}

export interface EntityAuditSearch {
  entityId: string;
  entityType: string;
  updateType?: string;
  sort?: EntityAuditSort;
}

export interface EntityAuditSort {
  whenDecreasing?: boolean;
}

export class EntityAuditFactory {
  public static create<T>(params: { entity: Entity<T> }): EntityAudit {
    let now = new Date();
    return Builder<EntityAudit>()
      .entityId(params.entity.id)
      .entityType(params.entity.type)
      .updateType(params.entity.lastUpdateType)
      .when(params.entity.lastUpdatedDate)
      .updatedBy(params.entity.lastUpdatedBy)
      .updateSummary(params.entity.lastUpdateDiff)
      .build();
  }
}
